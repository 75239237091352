<template>
  <div class="home">
    <div class="top">
      <div class="swiper">
        <el-carousel height="240px">
          <el-carousel-item v-for="(item, index) in bannner" :key="index">
            <el-image
              style="width: 100%; cursor: pointer"
              :src="item.AdsImage"
              @click="gotobannerhref(item)"
              fit="cover"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="text">
        <div class="title">
          <div :class="index == 0 ? 'black' : 'witer'" @click="setindex(0)">
            每日帖子Top榜
            <img v-if="index == 0" src="../../static/img/sanjiao.png" alt="" />
          </div>
          <div :class="index == 1 ? 'black' : 'witer'" @click="setindex(1)">
            叨叨中的话题
            <img v-if="index == 1" src="../../static/img/sanjiao.png" alt="" />
          </div>
        </div>
        <top v-if="index == 0" :toplist="toplist" />
        <daodao v-if="index == 1" />
      </div>
    </div>
    <list
      :list="list"
      :total="total"
      @handleCurrentChange="handleCurrentChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import list from "./common/list.vue";
import top from "./common/top.vue";
import daodao from "./common/daodao.vue";
export default {
  data() {
    return {
      index: 0,
      form: {
        token: window.localStorage.getItem("token"),
        pageIndex: 1,
        pageSize: 30,
      },
      bannner: [],
      list: [],
      total: 0,
      toplist: [],
      porpstinde: 0,
    };
  },
  components: {
    list,
    top,
    daodao,
  },
  created() {
    this.gethomelist();
  },
  methods: {
    // banner跳转
    gotobannerhref(item) {
      if (item.JumpValue == "话题列表") {
        this.$router.push(`conversation?topic=${item.ParameterValue}`);
      }
      if (item.JumpValue == "帖子详情") {
        this.$router.push(`Postdetails?FindCode=${item.ParameterValue}`);
      }
    },
    // 下一页
    handleCurrentChange(val) {
      this.form.pageIndex = val;
      this.gethomelist();
    },
    // 改变size
    handleSizeChange(val) {
      console.log(val);
    },
    // 获取首页
    gethomelist() {
      this.$api.common.FindList(this.form).then((res) => {
        if (res.code == 200) {
          if (this.form.pageIndex == 1) {
            this.bannner = res.data.AdsImageList;
          }
          if (this.porpstinde == 0) {
            for (
              let index = 0;
              index < res.data.PopularPointsList.length;
              index++
            ) {
              res.data.PopularPointsList[index].Content = this.RichContent(
                res.data.PopularPointsList[index].Content
              );
              if (res.data.PopularPointsList[index].Content.length > 70) {
                res.data.PopularPointsList[index].Content.substring(0, 70) +
                  "...";
              }
            }
            this.toplist = res.data.PopularPointsList;
            this.porpstinde = 1;
          }
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].Content = this.RichContent(
              res.data.list[index].Content
            );
          }
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    setindex(index) {
      this.index = index;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>