<template>
  <div>
    <div class="fivelist" v-for="(item, index) in list" :key="index">
      <div class="name">
        <div :class="index > 2 ? 'indexthree' : 'index'">{{ index + 1 }}</div>
        <span @click="gotoconversation(item)">#{{ item.Title }}#</span>
      </div>
      <div class="look">
        <!-- <div><i class="el-icon-chat-dot-round"></i>54</div>
        <div><i class="el-icon-view"></i>888</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getdaodao();
  },
  methods: {
    // 去话题
    gotoconversation(item) {
      this.$router.push(`conversation?topic=${item.Title}`);
    },
    getdaodao() {
      let obj = {
        pageIndex: 1,
        pageSize: 5,
        token: window.localStorage.getItem("token"),
      };
      this.$api.common.hot_topic(obj).then((res) => {
        console.log(res);
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang='less' scoped>
.fivelist {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 10px;
  margin-top: 12px;
  cursor: pointer;

  .name {
    display: flex;
    align-items: center;

    .index {
      width: 30px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../../../static/img/paiming.png");
      background-size: 30px 26px;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #ffffff;
      box-sizing: border-box;
      padding-bottom: 3px;
    }
    .indexthree {
      width: 25px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #818181;
    }

    span {
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #e6bf84;
      margin-left: 10px;
    }

    p {
      width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #818181;
    }
  }

  .look {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #818181;
    opacity: 0.6;

    div {
      margin-left: 20px;
    }
  }
}
</style>
