<template>
  <div>
    <div class="fivelist" v-for="(item, index) in toplist" :key="index">
      <div class="name" @click="gotoPostdetails(item)">
        <div class="index">
          {{ index + 1 }}
        </div>
        <p v-html="msg(item.Content)">
          <!-- {{ item.Content | Content }} -->
        </p>
      </div>
      <!-- <div class="look">
        <div><i class="el-icon-chat-dot-round"></i>54</div>
        <div><i class="el-icon-view"></i>888</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toplist: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  filters: {
    Content(val) {
      if (val.length > 20) {
        return val.substring(0, 20) + "...";
      } else {
        return val;
      }
    },
  },
  created() {},
  methods: {
    gotoPostdetails(item) {
      this.$router.push(`Postdetails?FindCode=${item.FindCode}`);
    },
  },
};
</script>

<style lang='less' scoped>
.fivelist {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 10px;
  margin-top: 12px;
  cursor: pointer;

  .name {
    display: flex;
    // align-items: center;

    .index {
      width: 30px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../../../static/img/paiming.png");
      background-size: 30px 26px;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #ffffff;
      box-sizing: border-box;
      padding-bottom: 3px;
    }
    .indexthree {
      width: 25px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #818181;
    }

    span {
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #e6bf84;
      margin-left: 10px;
    }

    p {
      width: 400px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #818181;
      margin-left: 10px;
    }
  }

  .look {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #818181;
    opacity: 0.6;

    div {
      margin-left: 20px;
    }
  }
}
</style>
