<template>
  <div class="list">
    <div class="box" v-for="(item, index) in list" :key="index">
      <div class="boxmsg">
        <div class="avatar" @click="gotoOtherspersonal(item)">
          <img :src="item.Avatar" />
          <div class="Grade" v-if="item.Gender == '女'">
            {{ item.Grade }}
          </div>
          <div class="Grademan" v-if="item.Gender == '男'">
            {{ item.Grade }}
          </div>
        </div>
        <div class="messgein">
          <div class="name" @click="gotoOtherspersonal(item)">
            {{ item.Name }}
          </div>

          <div class="conter">
            <div class="left">
              <span
                @click="gotoPostdetails(item)"
                v-if="item.ForwardedName != ''"
                style="color: #818181"
                >转发:{{ item.ForwardedText | ForwardedText }}//{{
                  item.ForwardedName
                }}:</span
              >
              <span
                style="color: #e6bf84"
                v-if="item.Title != ''"
                @click="gotoconversation(item)"
                >#{{ item.Title }}#</span
              >
              <p
                style="color: #818181"
                v-html="item.Content"
                @click="gotoPostdetails(item)"
              >
                <!-- {{ item.Content }} -->
              </p>
            </div>
            <div class="right" @click="gotoPostdetails(item)">
              <div>
                <i class="el-icon-chat-dot-round"></i
                ><span style="margin-left: 5px">{{ item.Comment }}</span>
              </div>
              <div>{{ item.CreatTime | formatDate }}</div>

              <!-- <div><i class="el-icon-view"></i>{{ item.Browse }}</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="imgbox" v-if="item.ForwardedName == ''">
        <el-image
          style="width: 80px; height: 80px"
          :src="i"
          fit="cover"
          :preview-src-list="item.Image"
          v-for="(i, ind) in item.Image"
          :key="ind"
        ></el-image>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from "../../../static/js/formatDate";
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        token: window.localStorage.getItem("token"),
        pageKey: "",
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    // this.getlist();
  },
  methods: {
    // 去话题
    gotoconversation(item) {
      this.$router.push(`conversation?topic=${item.Title}`);
    },
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.MemberCode}`);
    },
    // 下一页
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    // 改变size
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    gotoPostdetails(item) {
      this.$router.push(`Postdetails?FindCode=${item.FindCode}`);
    },
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
    ForwardedText(val) {
      if (val.length > 15) {
        return val.substr(0, 15) + "...";
      }
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e6bf84 !important;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.list {
  width: 1195px;
  //   min-height: 726px;
  overflow-y: auto;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(172, 148, 113, 0.3);
  border-radius: 15px;
  box-sizing: border-box;
  padding: 0px 20px;
  .box {
    width: 100%;
    border-bottom: 1px solid #f8f8f8;
    .boxmsg {
      width: 100%;
      height: 90px;
      display: flex;
      align-items: center;

      .avatar {
        width: 48px;
        height: 48px;
        cursor: pointer;
        position: relative;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }
        .Grade {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 18px;
          height: 18px;
          background: #fa8fd1;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          // font-size: 13px;
          font-family: NotoSansHans;
          font-weight: 400;
          color: #ffffff;
        }
        .Grademan {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 18px;
          height: 18px;
          background: #836dfb;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-family: NotoSansHans;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .messgein {
        width: 100%;
        margin-left: 20px;
        .name {
          font-size: 20px;
          font-family: NotoSansHans;
          font-weight: normal;
          color: #545353;
          cursor: pointer;
          margin-bottom: 10px;
        }
        .conter {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            width: 560px;
            white-space: nowrap;
            font-size: 14px;
            font-family: NotoSansHans;
            font-weight: 100;
            display: flex;
            align-items: center;
            cursor: pointer;
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              padding: 0;
              margin: 0;
              // margin-top: 5px;
            }
          }
          .right {
            display: flex;
            align-items: center;
            color: #898989;
            cursor: pointer;
            div {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .imgbox {
      margin-bottom: 20px;
      margin-left: 70px;
      * {
        margin-right: 10px;
      }
    }
  }
}
</style>
